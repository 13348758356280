<template>
  <div class="w-full employ">
    <h1 class="my-6 text-3xl uppercase font-b">
      Employment <span>Application</span>
    </h1>
    <div class="mb-12 text-left intro">
      <p>
        We want you to be part of the Peace of Mind Cleaning &amp; Concierge
        Service Family!
      </p>
      <p>
        Here at Peace of Mind Cleaning &amp; Concierge Service we strive to
        create independence and greatness in all of our employees. Each day you
        clean independently which gives you the power to create a strong
        customer base, have consistent hours, and earn tips. The great thing is
        the ball is in your court; the more you put in, the more you are
        rewarded.
      </p>
      <p>
        Our cleaners are a key player in our company and our customer’s lives.
        Each time you assist in a customer’s home you are bringing Peace of Mind
        into their life and household.
      </p>
      <p>
        Benefits of a position with Peace of Mind Cleaning &amp; Concierge
        Service:
      </p>
      <ul>
        <li>
          <i class="p-1 text-xl fas fa-piggy-bank" /> Among the highest paid
          cleaning positions in the business
        </li>
        <li><i class="p-1 text-xl fas fa-piggy-bank" /> Paid mileage</li>
        <li><i class="p-1 text-xl fas fa-piggy-bank" /> Paid vacation</li>
        <li><i class="p-1 text-xl fas fa-piggy-bank" /> Tips</li>
        <li><i class="p-1 text-xl fas fa-piggy-bank" /> Potential bonuses</li>
        <li>
          <i class="p-1 text-xl fas fa-piggy-bank" /> The excitement of knowing
          you are starting a career with a growing company
        </li>
        <li>
          <i class="p-1 text-xl fas fa-piggy-bank" /> Opportunities for job
          advancement
        </li>
      </ul>
      <p>
        If you would like to join our team, please fill-out the below shortened
        application.
      </p>
    </div>

    <div
      class="w-full p-4 mx-auto my-3 border rounded-lg shadow-lg md:p-8 p-none border-gray card sm:w-3/4 md:w-1/2"
    >
      <transition name="fade">
        <div
          class="p-3 mb-4 text-xl text-white bg-green-500 border border-green-500 rounded-md shadow-md opacity-50"
          v-if="showSuccess"
        >
          Your employment application has been received successfully.
        </div>
      </transition>
      <!-- <button @click="showSuccess = !showSuccess">Toggle</button> -->
      <div class="text-left">
        <form class="text-left login-form" @submit.prevent="submitForm">
          <label class="block">
            <span class="text-xs text-red-700" v-if="invalid.nameRequired"
              >Name field is required</span
            >
            <input
              type="text"
              placeholder="Name*"
              name="name"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              v-model="form.name"
              data-required="true"
            />
          </label>
          <label class="block">
            <span class="text-xs text-red-700" v-if="invalid.phoneRequired"
              >Phone field is required</span
            >
            <input
              type="text"
              placeholder="Phone*"
              name="phone"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              v-model="form.phone"
              data-required="true"
            />
          </label>
          <label class="block">
            <span
              class="block text-xs text-red-700"
              v-if="invalid.emailRequired"
              >Email field is required</span
            >
            <span class="block text-xs text-red-700" v-if="invalid.emailFormat"
              >Please enter a valid email address</span
            >
            <input
              type="text"
              placeholder="Email*"
              name="email"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              v-model="form.email"
              data-required="true"
              data-email="true"
            />
          </label>
          <label class="block">
            <span class="text-xs text-red-700" v-if="invalid.addressRequired"
              >Address field is required</span
            >
            <input
              placeholder="Address*"
              name="address"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              v-model="form.address"
              data-required="true"
            />
          </label>
          <label class="block">
            <span class="text-xs text-red-700" v-if="invalid.cityRequired"
              >City field is required</span
            >
            <input
              type="text"
              placeholder="City*"
              name="city"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              v-model="form.city"
              data-required="true"
            />
          </label>
          <div class="mt-4 ml-1">
            Full-time or Part-time desired?
            <label class="inline-flex items-center">
              <input type="radio" value="Full-time" v-model="form.position" />
              <span class="ml-2">Full-time</span>
              &nbsp; &nbsp;
              <input type="radio" value="Part-time" v-model="form.position" />
              <span class="ml-2">Part-time</span>
            </label>
          </div>
          <div class="mt-4 ml-1">
            Do you have a valid Driver’s License and an Insured vehicle for
            self-transportation?
            <label class="inline-flex items-center">
              <input type="radio" value="Yes" v-model="form.driversLicense" />
              <span class="ml-2">Yes</span>
              &nbsp; &nbsp;
              <input type="radio" value="No" v-model="form.driversLicense" />
              <span class="ml-2">No</span>
            </label>
          </div>
          <div class="mt-4 ml-1">
            Do you have any cleaning experience?
            <label class="inline-flex items-center">
              <input type="radio" value="Yes" v-model="form.experience" />
              <span class="ml-2">Yes</span>
              &nbsp; &nbsp;
              <input type="radio" value="No" v-model="form.experience" />
              <span class="ml-2">No</span>
            </label>
          </div>
          <label class="block mt-3">
            <span class="text-xs text-red-700" v-if="invalid.commentsRequired"
              >Comments field is required</span
            >
            <textarea
              placeholder="Comments*"
              name="comments"
              v-model="form.comments"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              data-required="true"
            ></textarea>
          </label>
          <button
            class="px-6 py-2 mt-2 text-lg text-gray-200 rounded hover:opacity-75"
            type="submit"
          >
            Submit
          </button>
        </form>
        <the-spinner v-if="loadingState" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, reactive, onBeforeMount, inject } from "vue";
import { logout, getUserName } from "../firebase.js";
import TheSpinner from "@/components/TheSpinner.vue";

export default {
  inject: ["global"],
  components: { TheSpinner },
  setup() {
    const invalid = reactive({
      nameRequired: false,
      emailRequired: false,
      phoneRequired: false,
      addressRequired: false,
      cityRequired: false,
      emailFormat: false,
      commentsRequired: false,
    });
    const form = reactive({
      name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      position: "Full-time",
      driversLicense: "Yes",
      experience: "Yes",
      comments: "",
    });
    // const appUrl = ref("http://localhost:5001/pomcs-com/us-central1/app");
    const appUrl = ref("https://us-central1-pomcs-com.cloudfunctions.net/app");
    const loadingState = ref(false);
    const showSuccess = ref(false);
    const submitForm = () => {
      validate();
    };
    const validate = () => {
      const requiredFields = document.querySelectorAll(".form-input");
      let formInvalid = false;
      requiredFields.forEach((item) => {
        if (item.dataset.required === "true" && item.value.length === 0) {
          invalid[`${item.name}Required`] = true;
          formInvalid = true;
        } else {
          invalid[`${item.name}Required`] = false;
        }
        if (item.dataset.email === "true" && !validateEmail(item.value)) {
          invalid[`${item.name}Format`] = true;
          formInvalid = true;
        } else {
          invalid[`${item.name}Format`] = false;
        }
      });
      if (formInvalid === false) {
        addEmployment();
      }
    };
    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };
    const addEmployment = () => {
      loadingState.value = true;
      axios
        .post(`${appUrl.value}/api/add/employment`, form)
        .then((result) => {
          console.log("contact upload result:", result);
          loadingState.value = false;
          successPopup();
        })
        .catch((err) => {
          console.log("err:", err);
          loadingState.value = false;
        });
    };
    const successPopup = () => {
      showSuccess.value = true;
      setTimeout(() => {
        showSuccess.value = false;
        form.name = "";
        form.email = "";
        form.phone = "";
        form.address = "";
        form.city = "";
        form.position = "Full-time";
        form.driversLicense = "Yes";
        form.experience = "Yes";
        form.comments = "";
      }, 4000);
    };
    onBeforeMount(() => {
      // name.value = getUserName();
    });
    return {
      form,
      invalid,
      submitForm,
      loadingState,
      showSuccess,
    };
  },
};
</script>

<style lang="scss" scoped>
form {
  padding: 0;
}
.bullet-list {
  text-align: left;

  li {
    margin: 0 0 8px 0;
  }
}
.hero {
  background-image: url(~@/assets/images/hero-img.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: 100%;
}
.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 0.5;
}
.fade-enter-active {
  transition: all 0.7s ease-in;
}
.fade-leave-from {
  opacity: 0.5;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 0.7s ease-out;
}
.intro p {
  margin-bottom: 16px;
}
.intro ul {
  // list-style: disc;
  margin: 0 0 1rem 1.5rem;
}
.login-form button {
  background-color: #265aa9;
}
.employ h1 {
  color: #265aa9;
}
</style>